import React from "react";

import {authRoles} from "app/auth";

const ProfileConfig = {
  settings: {
    layout: "admin",
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/account",
      exact: true,
      component: React.lazy(() => import("./admins")),
    },
    {
      path: "/account/new",
      exact: true,
      component: React.lazy(() => import("./admin")),
    },
  ],
};

export default ProfileConfig;
