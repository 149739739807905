import React from "react";
import i18next from "i18next";

import {authRoles} from "app/auth";
import en from "./i18n/en";

i18next.addResourceBundle("en", "dashboard", en);

const DashboardConfig = {
  settings: {
    layout: "admin",
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/category",
      exact: true,
      component: React.lazy(() => import("./categories")),
    },
    {
      path: "/category/new",
      exact: true,
      component: React.lazy(() => import("./category")),
    },
    {
      path: "/category/:id",
      exact: true,
      component: React.lazy(() => import("./category")),
    },
    {
      path: "/tag",
      exact: true,
      component: React.lazy(() => import("./tags")),
    },
    {
      path: "/tag/new",
      exact: true,
      component: React.lazy(() => import("./tag")),
    },
    {
      path: "/tag/:id",
      exact: true,
      component: React.lazy(() => import("./tag")),
    },
  ],
};

export default DashboardConfig;
