import React, { createRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import routes from "app/configs/menu.config";

import cx from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/weatherplus-admin/layouts/adminStyle.js";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/with_reducer";
import reducer from "app/store/reducers";
import * as AppActions from "app/store/actions";

var ps;

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const { ...rest } = props;
  const dispatch = useDispatch();

  const [mobileOpen, setMobileOpen] = useState(false);

  const { miniActive } = useSelector(state => state.appReducer);

  const color = "blue";
  const bgColor = "black";
  const logo = "/logo76.png";

  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });

  const mainPanel = createRef();

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sidebarMinimize = () => dispatch(AppActions.toggleMiniDrawer());

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Weather plus"}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={""}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            {props.children}
          </div>
        </div>

        <Footer fluid />
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  children: PropTypes.element,
};

export default withReducer("reducer", reducer)(Dashboard);
