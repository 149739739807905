import React from "react";
import Provider from "react-redux/es/components/Provider";
import {Router} from "react-router-dom";

import history from "@history";

import "assets/scss/weatherplus-admin.scss?v=1.9.0";
import "app/i18n/i18n.config";

import Authorization from "app/auth/authorization";
import Layout from "app/layout/layout";

import AppContext from "./app_context";
import routes from "./configs/routes.config";
import store from "./store";
import {Auth} from "./auth";

const App = () => (
  <AppContext.Provider value={{routes}}>
    <Provider store={store}>
      <Auth>
        <Router history={history}>
          <Authorization>
            <Layout />
          </Authorization>
        </Router>
      </Auth>
    </Provider>
  </AppContext.Provider>
);

export default App;
