import AuthConfig from "./auth/auth.config";
import ProfileConfig from "./profile/profile.config";
import TagConfig from "./tag/tag.config";
import PostConfig from "./post/post.config";
import AdminConfig from "./admin/admin.config";

const mainConfigs = [
  AuthConfig,
  ProfileConfig,
  TagConfig,
  PostConfig,
  AdminConfig,
];

export default mainConfigs;
