import React from "react";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import styles from "assets/jss/weatherplus-admin/layouts/authStyle.js";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const {...rest} = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  return (
    <div>
      <AuthNavbar brandText="Weatherplus Admin" {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div className={classes.fullPage}>
          {props.children}
          <Footer white />
        </div>
      </div>
    </div>
  );
}
