const domain = "https://weatherplus.vn";
// const domain = "http://localhost:3000";

const baseURL = `${domain}/api/admin`;
const accessTokenKey = "jwt_access_token";

const apiConfig = {
  baseURL,
  accessTokenKey,
};

export default apiConfig;
