import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import App from "./app/app";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <App />,
  document.getElementById("root")
);

reportWebVitals();
