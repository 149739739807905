import {Category, Description, LocalOffer, Person} from "@material-ui/icons";

const menuRoutes = [
  {
    path: "/post",
    name: "Quản lý bài viết",
    icon: Description,
  },
  {
    path: "/category",
    name: "Quản lý chuyên mục",
    icon: Category,
  },
  {
    path: "/tag",
    name: "Quản lý tag",
    icon: LocalOffer,
  },
  {
    path: "/account",
    exact: true,
    name: "Quản lý quản trị viên",
    icon: Person,
  },
];

export default menuRoutes;
