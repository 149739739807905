import React from "react";
import {Redirect} from "react-router-dom";

import {authRoles} from "app/auth";

const ProfileConfig = {
  settings: {
    layout: "admin",
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/",
      exact: true,
      component: () => <Redirect to="/post" />,
    },
    {
      path: "/post",
      exact: true,
      component: React.lazy(() => import("./posts")),
    },
    {
      path: "/post/new",
      exact: true,
      component: React.lazy(() => import("./post")),
    },
    {
      path: "/post/:id",
      exact: true,
      component: React.lazy(() => import("./post")),
    },
  ],
};

export default ProfileConfig;
