import React from "react";
import i18next from "i18next";

import {authRoles} from "app/auth";
import en from "./i18n/en";

i18next.addResourceBundle("en", "profile", en);

const ProfileConfig = {
  settings: {
    layout: "admin",
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/profile",
      exact: true,
      component: React.lazy(() => import("./profile")),
    },
  ],
};

export default ProfileConfig;
